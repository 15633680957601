import useMedia from '../useMedia';
import { BREAKPOINT_QUERIES } from './const';
import { ScreenTypeEnum, UseScreenReturnType } from './types';

export { BREAKPOINT_QUERIES };
declare global {
  interface ScreenOrientation {
    lock(orientation: 'portrait' | 'landscape'): Promise<void>;
  }
}
export default function useScreen(): UseScreenReturnType {
  const screen = useMedia(
    [BREAKPOINT_QUERIES.Desktop, BREAKPOINT_QUERIES.Tablet, BREAKPOINT_QUERIES.Mobile],
    [ScreenTypeEnum.Desktop, ScreenTypeEnum.Tablet, ScreenTypeEnum.Mobile],
    ScreenTypeEnum.Desktop,
  );

  const isDesktop = screen === ScreenTypeEnum.Desktop;
  const isTablet = screen === ScreenTypeEnum.Tablet;
  const isMobile = screen === ScreenTypeEnum.Mobile;
  const orientation = window.screen.orientation;
  const isLandScapeMode =
    orientation &&
    (orientation.type == 'landscape-primary' || orientation.type == 'landscape-secondary');
  const rotateScreenToLandscape = () => {
    if (orientation && orientation.lock) {
      try {
        orientation.lock('landscape');
      } catch (error) {
        console.error('Error locking orientation:', error);
      }
    }
  };

  return { screen, isDesktop, isTablet, isMobile, isLandScapeMode, rotateScreenToLandscape };
}
