import getEnabledItems from '@lyearn/core/utils/array/getEnabledItems';
import { TFunction } from '@lyearn/i18n';
import {
  Activity2Outline,
  Certificate2Icon,
  CompassOutline,
  File,
  FlagOutline,
  Flip,
  GridOutline,
  Home,
  LayoutOutline,
  MessageCircleOutline,
  MeterOutline,
  PeopleOutline,
  PieOutline,
  RocketOutline,
  SettingsOutlineLegacy,
  SurveyOutline,
  TvOutline,
} from '@lyearn/icons';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import { useAppConfig } from '@/hooks/useAppConfig';
import { QuestionManagerTabEnum } from '@/modules/question/views/QuestionManager/types';
import {
  UserSurveyManagerTabType,
  UserSurveyTypeRouteEnum,
} from '@/modules/user-survey/views/UserSurveyManager/types';
import routes from '@/pages/corporate/routes';

import { ReviewCycleTypeEnum } from '../../../../../../types/schema';
import { ReviewCycleManagerTabsEnum } from '../../../../../reviews/views/ReviewManager/hooks/useReviewCycleManager';

interface Item {
  id: string;
  text: string;
  icon?: JSX.Element;
  enabled: boolean;
  to?: string;
  action?: React.ReactNode;
  routes?: string[];
}

export interface SidebarItemProps extends Item {
  children?: Item[];
}

export type SideBarMode = 'admin' | 'learner' | undefined;

export type SidebarSection = {
  id: string;
  title?: string;
  children: SidebarItemProps[];
  className?: string;
};

export type SidebarItems = SidebarSection[];

const getSurveyManagerRoutes = (surveyType: UserSurveyTypeRouteEnum): string[] => [
  routes.UserSurveyManager(surveyType, UserSurveyManagerTabType.Active),
  routes.UserSurveyManager(surveyType, UserSurveyManagerTabType.Draft),
  routes.UserSurveyManager(surveyType, UserSurveyManagerTabType.Closed),
];

const getReviewManagerRoutes = (type?: ReviewCycleTypeEnum): string[] => {
  switch (type) {
    case ReviewCycleTypeEnum.Organizational:
      return [
        routes.ReviewManagerOrganisational(ReviewCycleManagerTabsEnum.Active),
        routes.ReviewManagerOrganisational(ReviewCycleManagerTabsEnum.Past),
      ];
    case ReviewCycleTypeEnum.Project:
      return [
        routes.ReviewManagerProject(ReviewCycleManagerTabsEnum.Active),
        routes.ReviewManagerProject(ReviewCycleManagerTabsEnum.Past),
      ];
    case ReviewCycleTypeEnum.Automated:
      return [
        routes.ReviewManagerRuleBased(ReviewCycleManagerTabsEnum.Active),
        routes.ReviewManagerRuleBased(ReviewCycleManagerTabsEnum.Past),
      ];
    default:
      return [
        routes.ReviewManager(ReviewCycleManagerTabsEnum.Active),
        routes.ReviewManager(ReviewCycleManagerTabsEnum.Past),
      ];
  }
};

const getEnabledItemsInSection = (items: SidebarItems): SidebarItems =>
  items.reduce((acc, val) => {
    const enabledItemsInSection = getEnabledItems(val.children);
    if (!enabledItemsInSection.length) {
      return acc;
    }

    return acc.concat({
      ...val,
      children: enabledItemsInSection.map((item) => ({
        ...item,
        children: getEnabledItems(item.children || []),
      })),
    });
  }, [] as SidebarSection[]);

function useSidebar(t: TFunction): SidebarItems {
  const { site } = useAppConfig();
  const communityId = site.defaultCommunitySettings?.communityId;
  const {
    [FeatureFlags.ShowUserProfile]: showUserProfile,
    [FeatureFlags.EnableOKR]: isOKREnabled,
    [FeatureFlags.EnableSkillMatrix]: isSkillMatrixEnabled,
    [FeatureFlags.EnableOnboarding]: showOnBoarding,
    [FeatureFlags.EnableReports]: showReports,
    [FeatureFlags.EnabledMGSAOnboarding]: showMGSAOnboarding,
    [FeatureFlags.EnabledReviewV2]: enabledReviewV2,
    [FeatureFlags.EnableUserSurvey]: isUserSurveyEnabled,
    [FeatureFlags.EnableActivity]: enableActivity,
    [FeatureFlags.EnableEmployeeJourney]: enableEmployeeJourney,
  } = useFeatureFlags();

  return getEnabledItemsInSection([
    {
      id: 'SECTION_1',
      children: [
        {
          id: 'DASHBOARD',
          text: t('Admin:Dashboard'),
          icon: <Home className="h-20 w-20" />,
          to: routes.Dashboard(),
          enabled: false,
        },
        {
          id: 'VIEW_ACADEMY',
          text: t('Admin:ViewAcademy'),
          icon: <LayoutOutline className="h-20 w-20" />,
          to: routes.ViewAcademy(),
          enabled: false,
        },
      ],
    },
    {
      id: 'SECTION_2',
      children: [
        {
          id: 'GETTING_STARTED',
          text: t('Admin:GettingStarted'),
          icon: <RocketOutline className="h-20 w-20" />,
          to: routes.GettingStarted(),
          enabled: showOnBoarding || showMGSAOnboarding,
        },
      ],
    },
    {
      id: 'SECTION_3',
      title: t('Admin:Learn'),
      children: [
        {
          id: 'GO_TO_LEARNER',
          text: t('Common:GoToLearner'),
          icon: <Flip className="h-20 w-20 rotate-90 transform" />,
          to: routes.Home(),
          enabled: !showUserProfile,
        },
        {
          id: 'LEARN_CONTENT',
          text: t('Admin:Content'),
          icon: <GridOutline className="h-20 w-20" />,
          enabled: true,
          children: [
            {
              id: 'LEARNING_CONTENT',
              text: t('Admin:AllContent'),
              to: routes.LearningContent(),
              routes: [routes.LearningContentArchived(), routes.LearningContent()],
              enabled: true,
            },
            {
              id: 'MY_RECENT',
              text: t('Admin:MyRecents'),
              enabled: true,
              to: routes.RecentLearningContent(),
            },
            {
              id: 'COLLECTIONS',
              text: t('Admin:Collections'),
              enabled: true,
              to: routes.CollectionManager(),
            },
            {
              id: 'QUESTION_BANK',
              text: t('Admin:QuestionBank'),
              to: routes.AdminQuestionManager(QuestionManagerTabEnum.Active),
              enabled: true,
            },
          ],
        },
        {
          id: 'SESSIONS',
          text: t('Common:Sessions'),
          icon: <TvOutline className="h-20 w-20" />,
          to: routes.AdminEvents(),
          routes: [routes.ManageFinishedEvents(), routes.AdminEvents()],
          enabled: true,
        },
        {
          id: 'ASSIGNMENTS',
          text: t('Common:Assignments'),
          icon: <File className="h-20 w-20" />,
          enabled: true,
          to: routes.Assignments(),
        },
        {
          id: 'CERTIFICATES',
          text: t('Admin:Certificates'),
          icon: <Certificate2Icon className="h-20 w-20" />,
          enabled: true,
          to: routes.AdminCertificates(),
        },
      ],
    },
    {
      id: 'SECTION_4',
      title: t('Admin:Align'),
      children: [
        {
          id: 'OKRS',
          text: t('Common:Routes.OKRs'),
          icon: <FlagOutline className="h-20 w-20" />,
          enabled: !!isOKREnabled,
          to: routes.CycleManager(),
        },
      ],
    },
    {
      id: 'SECTION_5',
      title: t('Admin:Perform'),
      children: [
        {
          id: 'SKILL_REVIEW',
          text: t('Admin:Reviews'),
          icon: <MeterOutline className="h-20 w-20" />,
          enabled: isSkillMatrixEnabled,
          routes: getReviewManagerRoutes(),
          children: [
            {
              id: 'AllReviews',
              text: t('Admin:AllReviews'),
              enabled: true,
              to: routes.ReviewManager(),
              routes: getReviewManagerRoutes(),
            },
            {
              id: 'Organizational',
              text: t('Admin:Organizational'),
              enabled: true,
              to: routes.ReviewManagerOrganisational(),
              routes: getReviewManagerRoutes(ReviewCycleTypeEnum.Organizational),
            },
            {
              id: 'Project',
              text: t('Admin:Project'),
              enabled: enabledReviewV2,
              to: routes.ReviewManagerProject(),
              routes: getReviewManagerRoutes(ReviewCycleTypeEnum.Project),
            },
            {
              id: 'Automated',
              text: t('Admin:RuleBased'),
              enabled: enabledReviewV2,
              to: routes.ReviewManagerRuleBased(),
              routes: getReviewManagerRoutes(ReviewCycleTypeEnum.Automated),
            },
            {
              id: 'Templates',
              text: t('Admin:QuestionBank'),
              enabled: enabledReviewV2 && isUserSurveyEnabled,
              to: routes.ReviewQuestionBank(),
            },
            {
              id: 'ActionPlan',
              text: t('Admin:ActionPlan'),
              enabled: enabledReviewV2,
              to: routes.AutomatedReviewActionPlan(),
            },
          ],
        },
        {
          id: 'METRICS',
          icon: <Activity2Outline className="h-20 w-20" />,
          text: t('Admin:Metrics'),
          to: routes.MetricsManager(),
          enabled: enableActivity,
        },
      ],
    },
    {
      id: 'SECTION_6',
      title: t('Admin:Engage'),
      children: [
        {
          id: 'USER_SURVEY',
          text: t('Admin:Surveys'),
          icon: <SurveyOutline className="h-20 w-20" />,
          routes: [],
          enabled: isUserSurveyEnabled,
          children: [
            {
              id: 'Engagement',
              text: t('Admin:Engagement'),
              enabled: true,
              to: routes.UserSurveyManager(UserSurveyTypeRouteEnum.Engagement),
              routes: getSurveyManagerRoutes(UserSurveyTypeRouteEnum.Engagement),
            },
            {
              id: 'Onboarding',
              text: t('Admin:Onboarding'),
              enabled: true,
              to: routes.UserSurveyManager(UserSurveyTypeRouteEnum.Onboarding),
              routes: getSurveyManagerRoutes(UserSurveyTypeRouteEnum.Onboarding),
            },
            {
              id: 'Pulse',
              text: t('Admin:Pulse'),
              enabled: true,
              to: routes.UserSurveyManager(UserSurveyTypeRouteEnum.Pulse),
              routes: getSurveyManagerRoutes(UserSurveyTypeRouteEnum.Pulse),
            },
            {
              id: 'Exit',
              text: t('Admin:Exit'),
              enabled: true,
              to: routes.UserSurveyManager(UserSurveyTypeRouteEnum.Exit),
              routes: getSurveyManagerRoutes(UserSurveyTypeRouteEnum.Exit),
            },
            {
              id: 'Feedback',
              text: t('Admin:Feedback'),
              enabled: true,
              to: routes.UserSurveyManager(UserSurveyTypeRouteEnum.Feedback),
              routes: getSurveyManagerRoutes(UserSurveyTypeRouteEnum.Feedback),
            },
            {
              id: 'QuestionBank',
              text: t('Admin:QuestionBank'),
              enabled: true,
              to: routes.UserSurveysQuestionBank(),
            },
            {
              id: 'ActionPlans',
              text: t('Admin:ActionPlans'),
              enabled: true,
              to: routes.UserSurveyActionPlans(),
            },
          ],
        },
        {
          id: 'COMMUNITY',
          icon: <MessageCircleOutline className="h-20 w-20" />,
          text: t('Common:Routes.Community'),
          to: communityId && routes.CommunityEditor(communityId),
          enabled: true,
        },
      ],
    },
    {
      id: 'SECTION_7',
      className: '!mt-auto !mb-0',
      children: [
        {
          id: 'JOURNEY',
          text: t('Admin:Journeys'),
          icon: <CompassOutline className="h-20 w-20" />,
          enabled: enableEmployeeJourney,
          routes: [routes.JourneyManager()],
          to: routes.JourneyManager(),
        },
        {
          id: 'USERS',
          text: t('Admin:Employees'),
          icon: <PeopleOutline className="h-20 w-20" />,
          enabled: true,
          routes: [routes.PendingLearners(), routes.DeactivatedLearners(), routes.Learners()],
          to: routes.Learners(),
        },
        {
          id: 'ANAYLTICS',
          text: t('Admin:Analytics'),
          icon: <PieOutline className="h-20 w-20" />,
          enabled: showReports,
          to: routes.StandardReports(),
          routes: [routes.CustomReports(), routes.StandardReports()],
        },
      ],
    },
    {
      id: 'SECTION_8',
      className: '!mb-16 sticky bottom-0 z-50 bg-white',
      children: [
        {
          id: 'SETTINGS',
          text: t('Common:Actions.Settings'),
          icon: <SettingsOutlineLegacy className="h-20 w-20" />,
          enabled: true,
          to: routes.Settings(),
        },
      ],
    },
  ]);
}

export default useSidebar;
