import config from 'config';
import { CookieAttributes } from 'js-cookie';

import logoutQuery from '@/helper/logoutQuery';
import { LoginToken } from '@/modules/auth/types';
import getUserVariables from '@/modules/auth/utils/getUserIdFromJWT';
import authRoutes from '@/pages/auth/routes';
import Cookie from '@/utils/general/cookie';

const isProd = config.env === 'production';
const cookieOptions: CookieAttributes = isProd ? { secure: true, sameSite: 'none' } : {};

export const setLoginCookies = (
  loginToken: LoginToken,
  { allowMemoryCookie = config.allow_memory_cookie } = {},
) => {
  Cookie.set('_uid', getUserVariables(loginToken.accessToken), { expires: 30, ...cookieOptions });
  if (config.rewrite_ws || allowMemoryCookie) {
    Cookie.set('_at', loginToken?.accessToken, { expires: 1 / 24, ...cookieOptions });
  }
  if (allowMemoryCookie) {
    Cookie.set('_rt', loginToken?.refreshToken, { expires: 30, ...cookieOptions });
  }

  return Promise.resolve();
};

export const setPublicCookies = (
  publicToken: string,
  { allowMemoryCookie = config.allow_memory_cookie } = {},
) => {
  if (config.rewrite_ws || allowMemoryCookie) {
    Cookie.set('_at', publicToken);
  }
};

export const logout = async () => {
  Cookie.remove('_uid');
  Cookie.remove('_at');
  Cookie.remove('_rt');
  Cookie.remove('notMe');
  (globalThis as any).sprChat?.('clearSession'); // Clear session of live chat
  await logoutQuery();

  // redirect to login page with redirect url
  if (globalThis.location?.href) {
    globalThis.location.href = `${authRoutes.Login()}?redirectTo=${
      globalThis.location.pathname
    }${encodeURIComponent(globalThis.location.search ?? '')}`;
  }

  return Promise.resolve();
};
