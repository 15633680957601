import { logout, setLoginCookies } from '@/modules/auth/utils';
import { AuthErrorCodes } from '@/modules/auth/utils/errorMessage';

import user from './currentUser';
import { request } from './request';

let refetchTokensPromise: any = null;

export const REFETCH_TOKEN_URL = '/api/auth/refreshTokens';

const fetchTokens = async () => {
  try {
    let body;

    let refreshToken = user._rT?.();
    if (refreshToken) {
      body = JSON.stringify({ refreshToken });
    }

    const response = await request(REFETCH_TOKEN_URL, {
      method: 'POST',
      body,
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });

    const tokens = await response.json();

    if (tokens.success) {
      await setLoginCookies(tokens.loginToken);

      return tokens.loginToken;
    } else if (tokens?.errorCode === AuthErrorCodes.TOKEN_INVALID) {
      await logout();
    } else {
      await logout();
      throw new Error(tokens?.errorCode);
    }
  } catch (e) {
    console.error('Error in fetching token', e);
    // MS: TODO Handle logout call
  } finally {
    refetchTokensPromise = null;
  }
};

const fetchAndSaveTokens = async () => {
  // refreshToken is not accessible on server side. it is saved on different path and can only be access by the client
  if (!__IS_BROWSER__) {
    return Promise.resolve();
  }

  if (refetchTokensPromise) {
    return refetchTokensPromise;
  }

  refetchTokensPromise = fetchTokens();
  return refetchTokensPromise;
};

export default fetchAndSaveTokens;
