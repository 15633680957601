import { DocumentNode, Kind } from 'graphql';
import { Operation, UseMutationState } from 'urql';

import { isGraphQLError } from './api/response';

export { default as useMutation } from '@/hooks/useMutation';
export { default as useQuery } from '@/hooks/useQuery';
export { default as useSubscription } from '@/hooks/useSubscription';
export type {
  DocumentInput,
  SubscriptionHandler,
  UseMutationExecute,
  UseMutationResponse,
  UseMutationState,
  UseQueryArgs,
  UseQueryResponse,
  UseQueryState,
  UseSubscriptionArgs,
  UseSubscriptionResponse,
} from 'urql';
export { useClient } from 'urql';

export const getOperationName = (query: DocumentNode): string | undefined => {
  for (const node of query.definitions) {
    if (node.kind === Kind.OPERATION_DEFINITION) {
      return node.name ? node.name.value : undefined;
    }
  }
};
export const getValueFromOperation = (operation?: Operation, key?: string) => {
  if (!operation) {
    return null;
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  // @ts-ignore
  return fetchOptions?.headers?.[key];
};

export const getOpNameFromOperation = (operation?: Operation) =>
  getValueFromOperation(operation, 'x-operation-name');
export const getRequestIdFromOperation = (operation?: Operation) =>
  getValueFromOperation(operation, 'x-request-id');

export const handleMutationState = (mutationState: UseMutationState<any, any>) => {
  return {
    isFetching: mutationState.fetching,
    isError: isGraphQLError(mutationState.error),
    isStale: mutationState.stale,
  };
};
