export enum FeatureFlags {
  EnableLyearnConnect = 'enable-lyearn-connect',
  EnableOneOnOne = 'enable-one-on-one',
  EnableOKR = 'enable-okr',
  EnableDepartments = 'enable-departments',
  EnableActivity = 'enable-activity',
  EnableSkillMatrix = 'enable-skill-matrix',
  EnableSelfLearningContent = 'enable-self-learning-content',
  EnablePraise = 'enable-praise',
  EnableMeeting = 'enable-meeting',
  EnableFeedback = 'enable-feedback',
  EnableHabits = 'enable-habits',
  ShowUserProfile = 'show-user-profile',
  EnableMarketplaceContent = 'enable-marketplace-content',
  EnableSharedCourseDuplication = 'enable-shared-course-duplication',
  EnableReports = 'enable-reports',
  EnableOnboarding = 'enable-onboarding',
  EnableContextualHomepage = 'enable-contextual-homepage',
  EnableLyearnAI = 'enable-lyearn-ai',
  EnableExternalContent = 'enable-external-content',
  EnabledMGSAOnboarding = 'enable-mgsa-onboarding',
  EnabledQuizSyncSkillScore = 'enable-quiz-sync-skill-score',
  EnabledReviewV2 = 'enable-reviews-v2',
  EnableUserSurvey = 'enable-user-survey',
  EnableUserSurveyCadenceCustomFrequency = 'enable-user-survey-cadence-custom-frequency',
  OnlyAllowInIframe = 'only-allow-in-iframe',
  EnableSubWorkspacesForQuestions = 'enable-subworkspaces-for-questions',
  EnablePublicFeedbackSurvey = 'enable-public-feedback-survey',
  EnableMultiLanguage = 'enable-multi-lang',
  EnableOverviewTab = 'enable-employee-overview-tab',
  EnableLearnerProfileJoinedDate = 'enable-profile-join-date',
  DefaultRestricted = 'default-restricted',
  DisableFeedback = 'disable-feedback',
  AutoRedirection = 'auto-redirection',
  EnableEmployeeJourney = 'enable-employee-journey',
  DisableContentAccessEventOnCompletion = 'disable-content-access-event-on-completion',
  RemoveCourseCover = 'remove-course-cover',
  EnablePathEditorDueDate = 'enable-path-editor-due-date',
  EnablePathOptionalContent = 'enable-path-optional-content',
  EnableDownloadVideo = 'enable-download-video',
  EnableFeedbackAfterNDays = 'enable-feedback-after-n-days',
}
