/* eslint-disable no-process-env */
import './loadEnv';

import Platform from '@lyearn/core/utils/Platform';
// @ts-ignore
import { areCookiesEnabled } from '@/utils/general/cookie';

const window_host_domain = typeof window !== 'undefined' ? window?.location?.host : undefined;
const is_web = Platform.OS === 'web';
const is_prod = process.env.NODE_ENV === 'production';
const vercel_env = process.env.NEXT_PUBLIC_VERCEL_ENV;

const host_domain = window_host_domain ?? process.env.NEXT_PUBLIC_HOST!;
/**
 * is mobile local development it is connected to meet. So secure connection is required.
 */
const secure_protocol =
  is_prod || !is_web || (window_host_domain && window.location.protocol.includes('https'));

const http_protocol = secure_protocol ? 'https' : 'http';
const ws_protocol = 'wss';

const origin = `${http_protocol}://${host_domain}`;

// used only on vercel
const API_HOST = process.env.NEXT_PUBLIC_HOST;

// nextjs/vercel does not support ws proxy, so we are using api domain for those
const rewrite_ws = (vercel_env || !is_prod) && is_web;

const config = {
  is_prod,
  env: process.env.NODE_ENV,
  vercel_env,
  http_protocol,
  build_env: process.env.NEXT_PUBLIC_ENV,

  mobile_build_environment: process.env.BUILD_ENVIRONMENT,

  uploads_endpoint: process.env.NEXT_PUBLIC_UPLOADS_ENDPOINT!,
  cloud_proxy_get_object: process.env.NEXT_PUBLIC_CLOUDPROXY_GETOBJECT,

  // should be defined on the client. for server use from req, NEXT_PUBLIC_HOST is for mobile
  host_domain,
  origin,
  ws_origin: `${ws_protocol}://${rewrite_ws ? API_HOST : host_domain}`,
  /** @deprecated only for websockets in vercel and dev env **/
  rewrite_ws,
  // @ts-ignore
  allow_memory_cookie: __IS_BROWSER__ && !areCookiesEnabled(),

  uppy_companion_url: `${origin}/api/cloudProxy/uppy`,
  cdn_domain: process.env.NEXT_PUBLIC_CDN_URL,
  app_version: process.env.APP_VERSION,
  public_text_editor_web_socket: process.env.NEXT_PUBLIC_TEXT_EDITOR_WEB_SOCKET_URL!,
  public_external_camera_url: process.env.NEXT_PUBLIC_EXTERNAL_CAMERA_URL!,
  jitsi_script_url: process.env.NEXT_PUBLIC_JITSI_SCRIPT!,
  lightspeed_domain: process.env.NEXT_PUBLIC_LIGHTSPEED_DOMAIN,

  segment_analytics_id: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_ID,
  firebase_web_notification_token: process.env.NEXT_PUBLIC_FIREBASE_WEB_NOTIFICATION_TOKEN,
  dropbox_token: process.env.NEXT_PUBLIC_DROPBOX_TOKEN,
  openreplay_token: process.env.NEXT_PUBLIC_OPENREPLAY_TOKEN,
  image_server: process.env.NEXT_PUBLIC_IMAGE_SERVER,
  scorm_container_url: process.env.NEXT_PUBLIC_SCORM_CONTAINER_URL,

  apple: {
    login_client_id: process.env.APPLE_LOGIN_CLIENT_ID!,
    login_redirect_uri: process.env.APPLE_LOGIN_REDIRECT_URL!,
  },
  google: {
    web_client_id: process.env.GOOGLE_WEB_CLIENT_ID,
    ios_client_id: process.env.GOOGLE_WEB_CLIENT_ID_IOS,
    analytics_id: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
    maps_api_key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
  },

  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_MEASUREMENT_ID,
  },

  rudderstack: {
    write_key: process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY,
    data_plane_url: process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL,
  },
};

if (config.image_server?.endsWith('/')) {
  throw new Error('image_server should not end with /');
}

export default config;

export function setHostDomain(host_domain: string) {
  config.host_domain = host_domain;

  config.origin = `${http_protocol}://${config.host_domain}`;
  config.ws_origin = `${ws_protocol}://${rewrite_ws ? API_HOST : config.host_domain}`;
  config.uppy_companion_url = `${config.origin}/api/cloudProxy/uppy`;

  console.log('setHostDomain called');
}
